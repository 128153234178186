<template>
  <div id="home">

    <b-navbar toggleable="lg" type="dark" :sticky="true">
      <b-navbar-brand href="/" class="title-font"><img src="../assets/images/Ford-oval-sm.png" alt="FAWEAR" class="header-logo">Field Academy Activity Report</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class="float-right"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown v-if="isAdmin" right>
            <b-dropdown-header>Reports / Management</b-dropdown-header>
            <b-dropdown-item :to="{ name: 'TimeManagementIndex' }">Activity Reports</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'MarketAreaReport' }">Activity Report Summary</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'ReportsTrained' }">Positions Trained by Coaches Report</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'ReportsIndex' }">Reports Dashboard</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'UserReportsIndex' }">User Specific Reports</b-dropdown-item>
            <b-dropdown-divider />
            <template slot="button-content" :to="{ name: 'ActivityIndex'}">Admin</template>
            <b-dropdown-header>Admin Management</b-dropdown-header>
            <b-dropdown-item :to="{ name: 'ActivityIndex' }">Activities</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'MarketAreaIndex' }">Market Areas</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'PhaseIndex' }">Phases</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'RegionIndex' }">Regions</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'TimeAmountIndex' }">Time Amounts</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'TopicIndex' }">Audiences</b-dropdown-item>
<!--            <b-dropdown-item :to="{ name: 'TraineeRoleIndex' }">Trainee Roles</b-dropdown-item>-->
<!--            <b-dropdown-item :to="{ name: 'RegionUserIndex' }">Region Associations</b-dropdown-item>-->
            <b-dropdown-divider />
            <b-dropdown-header>User Management</b-dropdown-header>
            <b-dropdown-item :to="{ name: 'UserCoachesIndex' }">Coaches</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'UserTraineeIndex' }">Trainees</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'UserIndex' }">All Users</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item :to="{ name: 'UserShow', params: { id: jwt.id } }">Profile</b-nav-item>
          <b-nav-item :to="{ name: 'UserEdit', params: { id: jwt.id } }">Change Password</b-nav-item>
          <b-nav-item @click="logout">Log Out</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <!-- Main Content of the Page -->
    <div class="container-fluid">
      <div class="row">
        <main role="main" class="col-md-12 ml-sm-auto col-lg-12 pt-3 px-4">
          <keep-alive include="TimeManagementIndex">
            <router-view></router-view>
          </keep-alive>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/common/services/auth.service';
import JwtService from '@/common/services/jwt.service';
import PermissionsService from '@/common/services/user_permissions.service';

export default {
  name: 'Home',
  data() {
    return {
      name: null,
      jwt: JwtService.parseJwt(),
      isAdmin: null,
    };
  },
  created() {
    this.isAdmin = PermissionsService.isAdmin(this.jwt.role);
  },
  methods: {
    logout() {
      AuthService.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
  .templates-container {
    margin: 0 auto;
    width: 100%;
  }
  .view-icons {
    float: right;
  }
  .view-icon {
    width: 50px;
  }
  .app-directory {
    float: left;
    width: 175px;
  }
  .black-border {
    margin: 0;
    padding: 2.5px;
    float: left;
    border: 1px solid black;
  }
  .template {
    float: left;
    padding: 15px 30px;
    width: 25%;
    text-align: center;
    color: #7f7f7f !important;
  }
  .box-shadow {
    box-shadow: 0 2px 6px 3px #ccc;
  }
  .app-title {
    font-size: 1.5em !important;
  }
  .title-font {
    font-size: 1rem !important;
  }
</style>
